import Vue from 'vue';

Vue.filter('phone', (value) => {
  if (value?.match(/^\+[0-9]{11,12}(x[0-9]{1,})?$/g)) {
    const split = value?.split('x');
    let valueBeforeExtension = split[0].replace(/\D/g, '');
    let maskedValue;
    if (valueBeforeExtension?.length === 10) {
      maskedValue = `(${valueBeforeExtension.substr(
        0,
        3,
      )}) ${valueBeforeExtension.substr(3, 3)}-${valueBeforeExtension.substr(
        6,
        4,
      )}`;
      valueBeforeExtension = `1${valueBeforeExtension}`;
    } else if (valueBeforeExtension?.length === 11) {
      maskedValue = `+${valueBeforeExtension.substr(
        0,
        1,
      )} (${valueBeforeExtension.substr(1, 3)}) ${valueBeforeExtension.substr(
        4,
        3,
      )}-${valueBeforeExtension.substr(7, 4)}`;
    } else if (valueBeforeExtension?.length === 12) {
      maskedValue = `+${valueBeforeExtension.substr(
        0,
        2,
      )} (${valueBeforeExtension.substr(2, 3)}) ${valueBeforeExtension.substr(
        5,
        3,
      )}-${valueBeforeExtension.substr(8, 4)}`;
    }
    return `${maskedValue}${split[1] ? ` Ext. ${split[1]}` : ''}`;
  }
  return value;
});
