import Vue from 'vue';
import * as esComponents from '@86-repairs/design-system';
import * as esGlobalComponents from '@86-repairs/global-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as validator from 'email-validator';
import { abilitiesPlugin, Can } from '@casl/vue';
import './filters/date-filters';
import './filters/currency-filters';
import './filters/phone-filters';
import VuePluralize from 'vue-pluralize';
import utcPlugin from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as Sentry from '@sentry/vue';
import { ability } from '@/plugins/ability';
import { createPinia, PiniaVuePlugin } from 'pinia';
import router from './router';
import esApiV3 from './service/esapiv3.service';
import s3Plugin from './plugins/s3';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import config from './config';

dayjs.extend(utcPlugin);

Vue.config.productionTip = false;

Object.keys(esComponents).forEach((component) => {
  Vue.component(component, esComponents[component]);
});

Object.keys(esGlobalComponents).forEach((component) => {
  Vue.component(component, esGlobalComponents[component]);
});

Vue.filter('numFormat', numFormat(numeral));

// CASL setup
Vue.use(abilitiesPlugin, ability);
Vue.component('CaslCan', Can);

Vue.use(s3Plugin, { axios: esApiV3 });

Vue.use(VuePluralize);

Vue.use(VueReCaptcha, {
  siteKey: import.meta.env.VITE_RECAPTCHA_KEY,
  loaderOptions: { autoHideBadge: true },
});

Sentry.init({
  Vue,
  enabled: ['production', 'stage'].includes(config.mode),
  environment: config.mode,
  release: config.sentryVersion,
  dsn: 'https://a5ab8db563294fcab4ba36190d057fa6@o4504555716673536.ingest.sentry.io/4504555802787841',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        'localhost',
        'eightysix-api-stage.86repairs.com',
        'eightysix-api.86repairs.com',
        /^\//,
      ],
    }),
    new Sentry.Replay({
      sessionSampleRate: 0.0,
      errorSampleRate: 0.01,
    }),
  ],
  tracesSampleRate: config.mode === 'production' ? 0.1 : 0.25,
  replaysOnErrorSampleRate: 0.01,
  replaysSessionSampleRate: 0.0,
  ignoreErrors: ['Network Error', 'Request failed with status code 400'],
});

Vue.set(Vue.prototype, 'validator', validator);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount('#app');

// Purges amplitude local storage keys to fully fix a "TOO MANY GROUPS" error that
// was caused by users in customers with many locations. This group issue was resolved in 4.10
// but since Amplitude caches events in local storage, we will need to purge these keys to fully fix the issue.
// This can be removed after 4.10 is released and all users have updated to the latest version.
if (localStorage && !localStorage.getItem('hasPurgedAmplitudeKeys410')) {
  const amplitudeKeys = Object.keys(localStorage).filter((key) =>
    key.startsWith('amplitude_'),
  );
  amplitudeKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
  localStorage.setItem('hasPurgedAmplitudeKeys410', 'true');
  // eslint-disable-next-line no-restricted-globals
  location?.reload();
}
