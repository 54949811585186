import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import {
  addNote,
  getAttachmentsForRequest,
  getRequestDetailById,
  getTaggableUsers,
  uploadAttachment,
  getProofOfValueMetricsForRequest,
} from '@/service/request.service';
import { getCommunicationLogs } from '@/service/telephony.service';
import {
  getSelfServiceRequestById,
  getSelfServicePmRequestHistory,
  getRequestPrompts,
  getFollowUps,
  getPmChecklistItemsForRequest,
  markPmChecklistItem,
  bulkCrudPmChecklistItem,
  getAutomations,
} from '@/service/self-service.service';
import { allActivityTypes } from '@/constants/activityTypes';
import { useSnackbarStore } from '@/stores/snackbarStore';

const DEFAULT_FILTERS = {
  locationId: [],
  requestPriority: null,
  serviceCategoryId: null,
  assignedToVendorId: null,
  requestStatus: 'Request Open',
  requestType: null,
  warrantyEligible: null,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

export const useRequestStore = defineStore('request', {
  state: () => ({
    priorityObjects: Object.freeze({
      LOW: 'Low',
      MEDIUM: 'Medium',
      HIGH: 'High',
      EMERGENCY: 'Emergency',
    }),
    contactMethods: Object.freeze({
      CALL: 'Call',
      EMAIL: 'Email',
      TEXT: 'Text',
      CHAT: 'Chat',
    }),
    typeObjects: Object.freeze({
      INCIDENT: 'Incident',
      PM: 'Preventative',
    }),
    filter: cloneDeep(DEFAULT_FILTERS),
    request: null,
    proofOfValueMetrics: {},
    attachments: [],
    pagination: null,
    taskBarCurrentTab: null,
    totalNumberOfCommunicationItems: 0,
    communicationsTableData: null,
    requestPmHistory: [],
    prompts: [],
    followUps: [],
    pmChecklistItems: [],
    taggableUsers: [],
    openPreviousDispatchAccordion: null,
    automations: [],
  }),
  actions: {
    setFilter(filter) {
      this.filter = filter;
    },
    clearFilter() {
      this.filter = cloneDeep(DEFAULT_FILTERS);
    },
    setPagination(pagination) {
      this.pagination = pagination;
    },
    async getRequestDetail(id) {
      this.getRequestAttachments(id);
      this.getRequestProofOfValueMetrics(id);
      const result = await getRequestDetailById(id);
      this.request = result;
    },
    async getRequestProofOfValueMetrics(id) {
      const savingsMetrics = await getProofOfValueMetricsForRequest(id);
      this.proofOfValueMetrics = savingsMetrics ?? {};
    },
    async getRequestAttachments(id) {
      const attachments = await getAttachmentsForRequest(id);
      this.attachments = attachments ?? [];
    },
    setAttachments(attachments) {
      this.attachments = attachments ?? [];
    },
    async addNote({ text, actionRequired }) {
      if (this.request.id) {
        await addNote(this.request.id, { text, actionRequired });
        this.getRequestDetail(this.request.id);
      }
    },
    async uploadRequestAttachment({
      attachment,
      attachmentType,
      requestId,
      taskId,
    }) {
      const theRequestId = this.request?.id || requestId;
      if (theRequestId) {
        const [code] = await uploadAttachment(
          theRequestId,
          attachment,
          attachmentType,
          taskId,
        );
        if (code === 202) {
          const snackbarStore = useSnackbarStore();
          snackbarStore.customMessage(
            `${attachmentType} already uploaded for request`,
          );
        } else {
          this.getRequestAttachments(theRequestId);
        }
      }
    },
    clearRequest() {
      this.request = null;
    },
    setTaskBarTab(tabIndex) {
      this.taskBarCurrentTab = tabIndex;
    },
    async getCommunicationTableData(query) {
      const response = await getCommunicationLogs({
        ...query,
      });
      this.totalNumberOfCommunicationItems = response.count;
      this.communicationsTableData = response.data;
    },
    async getSSORequestDetail(id) {
      const [
        result,
        prompts,
        followUps,
        pmChecklistItemsForRequest,
        automations,
      ] = await Promise.allSettled([
        getSelfServiceRequestById(id),
        getRequestPrompts(id),
        getFollowUps(id),
        getPmChecklistItemsForRequest(id, ['completedByUser']),
        getAutomations(id),
      ]);
      if (result.status === 'fulfilled') {
        const request = result.value;
        this.request = request;
        if (
          request?.requestType === 'Self Service Preventative' &&
          request?.pmThreadId?.length
        ) {
          const requests = await getSelfServicePmRequestHistory(
            request?.pmThreadId,
          );
          this.requestPmHistory =
            requests?.filter((r) => r.id !== request.id) ?? [];
        } else {
          this.requestPmHistory = [];
        }
      }
      if (prompts.status === 'fulfilled') {
        this.prompts = prompts.value;
      }
      if (followUps.status === 'fulfilled') {
        this.followUps = followUps.value;
      }
      if (pmChecklistItemsForRequest.status === 'fulfilled') {
        this.pmChecklistItems = pmChecklistItemsForRequest.value.data;
      }
      if (automations.status === 'fulfilled') {
        this.automations = automations.value;
      }
    },
    async getPromptInfo(requestId) {
      const prompts = await getRequestPrompts(requestId);
      this.prompts = prompts;
    },
    async getRequestById(id) {
      const result = await getSelfServiceRequestById(id);
      this.request = result;
    },
    clearRequestPmHistory() {
      this.requestPmHistory = [];
    },
    async markRequestPmChecklistItem({ checklistItemId, status }) {
      const updatedItem = await markPmChecklistItem({
        id: checklistItemId,
        status,
      });
      let updatedPmChecklistItems = [...this.pmChecklistItems];
      // update the item in the array of checklist items with the updated item
      updatedPmChecklistItems = updatedPmChecklistItems.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
      this.pmChecklistItems = updatedPmChecklistItems;
    },
    async bulkCrudPmChecklistItem(payload) {
      await bulkCrudPmChecklistItem(payload);
      const getRefreshedItems = (
        await getPmChecklistItemsForRequest(this.request.id, [
          'completedByUser',
        ])
      ).data;
      this.pmChecklistItems = getRefreshedItems;
    },
    async getTaggableUsers(requestId) {
      const taggableUsers = await getTaggableUsers(requestId);
      this.taggableUsers = taggableUsers;
    },

    async getAutomations(requestId) {
      const automations = await getAutomations(requestId);
      this.automations = automations;
    },
  },
  getters: {
    priorities: (state) => Object.values(state.priorityObjects),
    types: (state) => Object.values(state.typeObjects),
    requestId: (state) => state.request?.id,
    isIncident: (state) =>
      state.request.requestType === 'Incident' ||
      state.request.requestType === 'Self Service Incident',
    isPm: (state) =>
      state.request.requestType === 'Preventative' ||
      state.request.requestType === 'Self Service Preventative',
    dispatchConfirmationActivityObjects: (state) =>
      state.request?.activities?.filter(
        (it) => it.activityType === allActivityTypes.DISPATCH_CONFIRMATION,
      ),
    dispatchConfirmationTaskObjects: (state) =>
      state.request?.tasks?.filter(
        (it) =>
          it.activityType === allActivityTypes.DISPATCH_CONFIRMATION &&
          !it.completedAt,
      ),
  },
});
