export class Pagination {
  constructor(query, limit, func) {
    this.query = query;
    this.limit = limit;
    this.function = func;
    this.offset = 0;
    this.count = 0;
    this.items = [];
    this.loading = false;
    this.result = null;
    /**
     * @type {AbortController | null}
     */
    this.abortController = null;
  }

  /**
   * @private
   */
  async getAbortableItems(query) {
    if (this.abortController && !this.abortController.signal.aborted) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    const { signal } = this.abortController;
    const result = await this.function({
      ...query,
      limit: this.limit,
      offset: this.offset,
    });
    if (signal.aborted) {
      throw new Error('Aborted');
    }
    this.abortController = null;
    return result;
  }

  async getItems(resetItems = false) {
    if (this.offset < this.count || this.count === 0) {
      this.loading = true;
      try {
        this.result = await this.getAbortableItems(this.query);
      } catch (e) {
        // aborted
        return;
      }

      if (resetItems) {
        this.items = this.result.data;
      } else {
        this.items = [...this.items, ...this.result.data];
      }

      this.count = this.result.count;
      this.offset += this.limit;
      this.loading = false;
    }
  }

  setQuery(query, resetItems = true) {
    this.query = query;
    this.offset = 0;
    if (resetItems) {
      this.items = [];
    }
    this.count = 0;
  }

  async refreshItemById(id) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index < 0) return;

    if (!this.loading) {
      this.loading = true;
      const [item, counts] = await Promise.all([
        this.function({ id, ...this.query, limit: 1 }),
        this.function({ ...this.query, limit: 1 }),
      ]);
      if (item.data.length > 0) {
        [this.items[index]] = item.data;
      } else {
        this.items.splice(index, 1);
      }
      this.count = counts.count;
      this.result = counts;
      this.loading = false;
    }
  }
}
