import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  asset: () => 'assets',
  assetById: (id) => `assets/${id}`,
  assetDetailById: (id) => `assets/${id}/detailView`,
  assetSummariesByUserId: () => 'assets/summaries/user',
  assetPickerView: () => 'assets/summaries',
  assetPickerLocationList: () => 'locations/viewableAssetLocations',
  types: () => 'asset-types/all',
  equipmentModelNumbers: () => 'assets/modelNumbers',
  serviceVisits: (id) => `assets/${id}/service-visits`,
};

/**
 * Get a single asset by id
 * @param {string} id
 * @returns {object}
 */
export async function getAssetDetailById(id) {
  try {
    const res = await esApiV3.get(api.assetDetailById(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      `An error occurred while trying to get equipmentid: ${id}`,
    );
  }
  return null;
}

/**
 * Get a list of assets for asset picker
 * @param {object} query
 * @returns {Promise<Array>}
 */
export async function getAssetPickerView(query) {
  try {
    const res = await esApiV3.get(api.assetPickerView(), { params: query });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get asset picker view',
    );
  }
  return null;
}

/**
 * Get a multiple assets by id
 * @param {string[]} ids
 * @returns {object}
 */
export async function getAssetDetailsByIds(ids) {
  try {
    const res = await getAssetPickerView({
      id: ids,
      limit: ids.length,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      `An error occurred while trying to get equipment ids: ${ids.join(', ')}`,
    );
  }
  return null;
}

export async function getAssetPickerViewLocations() {
  try {
    const res = await esApiV3.get(api.assetPickerLocationList());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get asset picker view locations',
    );
  }
  return null;
}

/**
 * Get a multiple assets by user id, for all their locations and customer settings
 * @param {string} id
 * @returns {object}
 */
export async function getAssetSummariesForUser(query) {
  try {
    const res = await esApiV3.get(api.assetSummariesByUserId(), {
      params: query,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get asset picker view locations',
    );
  }
  return null;
}

export async function uploadEquipmentPhoto(id, photo, type) {
  const formData = new FormData();
  formData.append('file', photo);
  formData.append('assetId', id);
  formData.append('photoType', type);
  try {
    const res = await esApiV3.post('asset-photos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to upload equipment photo',
    );
  }
  return null;
}

export async function getAssetTypes() {
  try {
    const res = await esApiV3.get(api.types());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get asset types',
    );
  }
  return null;
}

export async function createAsset(asset) {
  try {
    const res = await esApiV3.post(api.asset(), asset);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      ex.response?.status === 403
        ? 'You do not have permission to add an asset to this location.'
        : 'An error occurred while trying to create asset',
    );
  }
  return null;
}

export async function updateAsset(id, asset) {
  try {
    const res = await esApiV3.patch(api.assetById(id), asset);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update asset',
    );
  }
  return null;
}

export async function getAssetModelNumbers(query) {
  try {
    const res = await esApiV3.get(api.equipmentModelNumbers(), {
      params: query,
    });
    return res.data.map((model) => model.modelNumber);
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get asset model numbers',
    );
  }
  return null;
}

export async function getInfrastructureByLocationId(locationId) {
  try {
    const res = await esApiV3.get(api.assetPickerView(), {
      params: {
        isInfrastructure: true,
        locationId,
        limit: 100,
      },
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get infrastructure',
    );
  }
  return null;
}

export async function getAssetServiceVisits(id) {
  try {
    const res = await esApiV3.get(api.serviceVisits(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get asset service visits',
    );
  }
  return null;
}
