import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  nextOccurrenceDate: (id) => `pm-schedules/${id}/next-occurrence-date`,
  getById: (id) => `pm-schedules/${id}`,
};

export async function updatePmNextOccurrence(id, nextOccurrenceDate) {
  try {
    const res = await esApiV3.patch(api.nextOccurrenceDate(id), {
      newDate: nextOccurrenceDate,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update preventative maintenance schedule',
    );
  }
  return null;
}

export async function getPmScheduleById(id) {
  try {
    const res = await esApiV3.get(api.getById(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to retrieve preventative maintenance schedule',
    );
  }
  return null;
}
