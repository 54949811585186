import esApiV3 from './esapiv3.service';
import exceptionService from './exception.service';

const api = {
  communicationLogs: () => 'telephony/communication-log',
};

export async function getCommunicationLogs(query) {
  return esApiV3
    .get(api.communicationLogs(), {
      params: {
        ...query,
      },
    })
    .then((result) => result.data)
    .catch((ex) => {
      exceptionService.showCustomError(
        ex,
        'An error occurred while trying to get communications',
      );
    });
}
