import esApiV3 from './esapiv3.service';
import exceptionService from './exception.service';

const api = {
  myTasksByQuery: () => 'tasks/self',
  taskById: (id) => `tasks/${id}`,
  completeTask: (id) => `tasks/${id}/complete`,
  taskShareRecipients: (id) => `tasks/${id}/share/recipients`,
  shareTask: (id) => `tasks/${id}/share`,
  savings: (id) => `tasks/${id}/savings`,
};

export async function getMyTasksByQuery(query) {
  return esApiV3
    .get(api.myTasksByQuery(), {
      params: { ...query },
    })
    .then((result) => result.data)
    .catch((ex) => {
      exceptionService.showCustomError(
        ex,
        'An error occurred while trying to get tasks',
      );
    });
}

export async function getTaskById(id) {
  return esApiV3
    .get(api.taskById(id))
    .then((result) => result.data)
    .catch((ex) => {
      exceptionService.showCustomError(
        ex,
        'An error occurred while trying to get task',
      );
    });
}

export async function completeTask(id, data) {
  return esApiV3
    .post(api.completeTask(id), data)
    .then((result) => result.data)
    .catch((ex) => {
      exceptionService.showCustomError(
        ex,
        'An error occurred while trying to complete task',
      );
    });
}

export async function getTaskShareRecipients(id) {
  return esApiV3
    .get(api.taskShareRecipients(id))
    .then((result) => result.data)
    .catch((ex) => {
      exceptionService.showCustomError(
        ex,
        'An error occurred while trying to get recipients for sharing task',
      );
    });
}

export async function shareTask(id, payload) {
  return esApiV3
    .post(api.shareTask(id), payload)
    .then((result) => result.data)
    .catch((ex) => {
      exceptionService.showCustomError(
        ex,
        'An error occurred while trying to share task',
      );
    });
}

export async function getSavings(id) {
  return esApiV3
    .get(api.savings(id))
    .then((result) => result.data)
    .catch((ex) => {
      exceptionService.showCustomError(
        ex,
        'An error occurred while trying to get savings',
      );
    });
}
