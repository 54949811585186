import { defineStore, getActivePinia } from 'pinia';
import { useSnackbarStore } from '@/stores/snackbarStore';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    loginProviders: [
      {
        name: 'Google',
        icon: 'mdi-google',
        color: '#DB4437',
      },
      {
        name: 'Microsoft',
        icon: 'mdi-microsoft',
        color: '#00A4EF',
      },
      {
        name: 'Apple',
        icon: 'mdi-apple',
        color: '#000000',
      },
      {
        name: 'LinkedIn',
        icon: 'mdi-linkedin',
        color: '#0073b1',
      },
      {
        name: 'Facebook',
        icon: 'mdi-facebook',
        color: '#1877F2',
      },
    ],
    appLoading: false,
    scrollToRequest: {
      currentTicketNumber: null,
      previousTicketNumber: null,
    },
  }),
  actions: {
    resetState() {
      const pinia = getActivePinia();
      // eslint-disable-next-line no-underscore-dangle
      pinia._s.forEach((store) => store.$reset());
    },
    copyToClipboard(thingToCopy) {
      navigator.clipboard.writeText(thingToCopy);
      const snackbarStore = useSnackbarStore();
      snackbarStore.customMessage(`"${thingToCopy}" copied to clipboard.`);
    },
    setAppLoading(isLoading) {
      this.appLoading = isLoading;
    },
    setScrollToTicketNumber({ currentTicketNumber, previousTicketNumber }) {
      this.scrollToRequest.currentTicketNumber = currentTicketNumber;
      this.scrollToRequest.previousTicketNumber = previousTicketNumber;
    },
    clearScrollToTicketNumber() {
      this.scrollToRequest.currentTicketNumber = null;
      this.scrollToRequest.previousTicketNumber = null;
    },
  },
});
