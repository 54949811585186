import { useSnackbarStore } from '@/stores/snackbarStore';

export default {
  show: (ex) => {
    const snackbarStore = useSnackbarStore();
    snackbarStore.generalError();
    throw ex;
  },
  showCustomError: (ex, errorMsg) => {
    const snackbarStore = useSnackbarStore();
    snackbarStore.customError(ex?.response?.data?.message || errorMsg);
    throw ex;
  },
  showError: (errorMsg) => {
    const snackbarStore = useSnackbarStore();
    snackbarStore.customError(errorMsg);
  },
};
