import Vue from 'vue';

Vue.filter('USD', (value) => {
  if (Number.isNaN(value)) return value;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter('USDk', (value) => {
  if (Number.isNaN(value) || value == null) return value;
  if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}k`;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
});
