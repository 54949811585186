import Vue from 'vue';
import Vuetify, {
  VAlert,
  VAutocomplete,
  VAvatar,
  VBtn,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VChip,
  VCol,
  VCombobox,
  VContainer,
  VDatePicker,
  VDialog,
  VDivider,
  VFileInput,
  VForm,
  VIcon,
  VImg,
  VItem,
  VItemGroup,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemGroup,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMenu,
  VProgressCircular,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSnackbar,
  VSpacer,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSwitch,
  VTab,
  VTabs,
  VTextarea,
  VTextField,
  VTooltip,
  VWindow,
  VWindowItem,
} from 'vuetify/lib';
import { Intersect, Resize, Scroll } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  components: {
    VAlert,
    VAutocomplete,
    VAvatar,
    VBtn,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VChip,
    VCol,
    VCombobox,
    VContainer,
    VDatePicker,
    VDialog,
    VDivider,
    VFileInput,
    VForm,
    VIcon,
    VImg,
    VItem,
    VItemGroup,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemGroup,
    VListItemIcon,
    VListItemSubtitle,
    VListItemTitle,
    VMenu,
    VProgressCircular,
    VRadio,
    VRadioGroup,
    VRow,
    VSelect,
    VSnackbar,
    VSpacer,
    VStepper,
    VStepperContent,
    VStepperHeader,
    VStepperItems,
    VStepperStep,
    VSwitch,
    VTab,
    VTextarea,
    VTextField,
    VTooltip,
    VTabs,
    VWindow,
    VWindowItem,
  },
  directives: {
    Intersect,
    Scroll,
    Resize,
  },
});

export default new Vuetify({
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#44ACC0',
        secondary: '#E93438',
        accent: '#112649',
        anchor: '#44ACC0',
        eightySixYellow: 'F3B61B',
        eightySixGrey1: 'D3DADE',
        eightySixGrey2: 'F1F1F5',
        white: '#FFFFFF',
      },
      dark: {
        primary: '#44ACC0',
        secondary: '#E93438',
        accent: '#112649',
        eightySixYellow: 'F3B61B',
        eightySixGrey1: 'D3DADE',
        eightySixGrey2: 'F1F1F5',
        white: '#FFFFFF',
      },
    },
  },
});
