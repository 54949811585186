import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  locationsForUser: () => 'locations/viewableLocations',
  locationsForMultiTenantUser: () =>
    'locations/viewableMultiTenantUserLocations',
  allLocations: () => 'locations/all',
  locationById: (locationId) => `locations/${locationId}`,
  vendorsForLocation: (locationId) => `locations/${locationId}/vendors`,
  vendorForLocation: (locationId, vendorId) =>
    `locations/${locationId}/vendors/${vendorId}`,
};

export async function getLocationsForUser(showError = true) {
  try {
    const res = await esApiV3.get(api.locationsForUser());
    return res.data;
  } catch (ex) {
    if (showError) {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get user locations',
      );
    }
  }
  return null;
}

/*
This function is used to get locations users that have access to multiple locations and multiple customer profiles
Eventually this should be merged with getLocationsForUser, but for now we are keeping it separate to avoid breaking changes
*/
export async function getLocationsForMultiTenantUser(showError = true) {
  try {
    const res = await esApiV3.get(api.locationsForMultiTenantUser());
    return res.data;
  } catch (ex) {
    if (showError) {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get user locations',
      );
    }
  }
  return null;
}

export async function getLocation(locationId) {
  try {
    const res = await esApiV3.get(api.locationById(locationId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      `An error occurred while trying to get locations, id=${locationId}`,
    );
  }
  return null;
}

export async function getAllLocations() {
  try {
    const res = await esApiV3.get(api.allLocations());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get all locations',
    );
  }
  return null;
}

export async function updateLocation(locationId, locationData) {
  await esApiV3.patch(api.locationById(locationId), locationData);
}

/**
 * Gets all vendors that are associated with a specific location
 * @param {string} locationId
 * @returns Promise<any[]>
 */
export async function getVendorsForLocation(locationId) {
  try {
    const res = await esApiV3.get(api.vendorsForLocation(locationId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      `An error occurred while trying to get vendors for location, id=${locationId}`,
    );
  }
  return null;
}

/**
 * Removes vendor from a specific location
 * @param {string} vendorId
 * @param {string} locationId
 * @returns Promise<void>
 */
export async function removeVendorFromLocation(vendorId, locationId) {
  try {
    const res = await esApiV3.delete(
      api.vendorForLocation(locationId, vendorId),
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
  return null;
}

/**
 * Removes vendor from all locations that a user has access to
 * @param {string} vendorId
 * @returns Promise<void>
 */
export async function removeVendorFromAll(vendorId) {
  try {
    const res = await esApiV3.delete(api.vendorForLocation('all', vendorId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
  return null;
}

/**
 * Adds vendor to a specific location
 * @param {string} vendorId
 * @param {string} locationId
 * @returns Promise<void>
 */
export async function addVendorToLocation(vendorId, locationId) {
  try {
    const res = await esApiV3.put(api.vendorForLocation(locationId, vendorId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
  return null;
}

/**
 * Adds vendor to all locations that a user has access to
 * @param {string} vendorId
 * @returns Promise<void>
 */
export async function addVendorToAll(vendorId) {
  try {
    const res = await esApiV3.put(api.vendorForLocation('all', vendorId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
  return null;
}
