export const pmActivityTypes = {
  RESCHEDULE_VENDOR_VISIT: 'Reschedule Vendor Visit',
  SCHEDULE_VENDOR_VISIT: 'Schedule Vendor Visit',
  VENDOR_SCHEDULED_COMMUNICATION: 'Vendor Scheduled Communication',
  VENDOR_RESCHEDULED_COMMUNICATION: 'Vendor Rescheduled Communication',
  REQUEST_VENDOR_PROOF_OF_WORK_COMMUNICATION:
    'Request Vendor Proof of Work Communication',
  RECONCILE_PM_WORK: 'Reconcile PM Work',
  PM_RESOLUTION_EMAIL: 'PM Resolution Email',
  CONFIRM_NEXT_OCCURRENCE: 'Confirm Next Occurrence',
};

export const autoActivityTypes = {
  DAY_OF_SERVICE_REMINDER_EMAIL: 'Day of Service Reminder Email',
  POST_VISIT_FOLLOW_UP_EMAIL: 'Post Visit Follow Up Email',
};

export const externalActivityTypes = {
  INTERNAL_TECHNICIAN_RESOLUTION: 'Internal Technician Resolution',
};

export const allActivityTypes = {
  ...pmActivityTypes,
  ...autoActivityTypes,
  ...externalActivityTypes,
  BLOCK_TASK: 'Block Task',
  CALL: 'Call',
  CHAT: 'Chat',
  COMMUNICATION: 'Communication',
  COMPLETE_ASSET: 'Complete asset',
  COMPLETE_VENDOR_RECORD: 'Complete Vendor Record',
  CUSTOMER_UPDATE_EMAIL: 'Customer Update Email',
  DAILY_ACTIVITY_REPORT: 'Daily activity report',
  DAY_OF_SERVICE_REMINDER_EMAIL: 'Day of Service Reminder Email',
  DEESCALATE_TASK: 'De-escalate Task',
  DISPATCH: 'Dispatch',
  DISPATCH_CONFIRMATION: 'Dispatch Confirmation',
  ESCALATE_TASK: 'Escalate Task',
  FOLLOW_UP: 'Follow up',
  NEW_SERVICE_REQUEST_EMAIL: 'New Service Request Email',
  OTHER: 'Other',
  PURCHASED_PARTS: 'Purchased parts',
  QUOTE: 'Quote',
  REQUEST_DISPATCH: 'Request Dispatch',
  REQUEST_DISPATCH_EMAIL: 'Request Dispatch Email',
  REQUEST_QUOTE_EMAIL: 'Request Quote Email',
  RESOLUTION_EMAIL: 'Resolution email',
  RESOLUTION: 'Resolution',
  RESOLUTION_FOLLOW_UP: 'Resolution Follow Up',
  SERVICE_PROVIDER_DISPATCHED_EMAIL: 'Vendor Dispatched Email',
  SERVICE_PROVIDER_FOLLOW_UP_VISIT_EMAIL: 'Vendor Follow Up Visit Email',
  SERVICE_PROVIDER_FOLLOW_UP: 'Vendor Follow Up',
  TEXT: 'Text',
  TRANSCRIBE_INVOICE: 'Transcribe Invoice',
  TROUBLESHOOTING: 'Troubleshooting',
  UNASSIGN_VENDOR: 'Unassign Vendor',
  UNBLOCK_TASK: 'Unblock Task',
  UPDATE_CUSTOMER: 'Update Customer',
  UPDATE_ETA_EMAIL: 'Update ETA Email',
  VENDOR_QUESTIONNAIRE: 'Questionnaire',
  WARRANTY_CHECK: 'Warranty check',
  WEEKLY_ACTIVITY_REPORT: 'Weekly activity report',
  WEEKLY_ACTIVITY_REPORT_FOR_LOCATION_GROUP:
    'Weekly activity report for location tag',
  CUSTOMER_NOTE: 'Customer Note',
};
