import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    snackbarVisible: false,
    snackbarText: '',
    snackbarColor: '',
  }),
  actions: {
    generalSuccess() {
      this.snackbarVisible = true;
      this.snackbarText = 'Save Successful';
      this.snackbarColor = 'success';
    },
    generalError() {
      this.snackbarVisible = true;
      this.snackbarText = 'An error occurred while trying to save';
      this.snackbarColor = 'error';
    },
    customMessage(message) {
      this.snackbarVisible = true;
      this.snackbarText = message;
      this.snackbarColor = 'info';
    },
    customSuccess(successMsg) {
      this.snackbarVisible = true;
      this.snackbarText = successMsg;
      this.snackbarColor = 'success';
    },
    customError(errorMsg) {
      this.snackbarVisible = true;
      this.snackbarText = errorMsg;
      this.snackbarColor = 'error';
    },
    resetSnackbar() {
      this.snackbarVisible = false;
      this.snackbarText = '';
      this.snackbarColor = '';
    },
  },
});
