import amplitude from 'amplitude-js';
import config from '@/config';

if (config.amplitudeEnabled) {
  amplitude.getInstance().init(config.amplitudeKey);
}

let disable = false;

export default {
  setDisabled(shouldDisable) {
    disable = shouldDisable;
  },
  setUserId(userId) {
    if (!config.amplitudeEnabled) {
      return;
    }

    amplitude.getInstance().setUserId(userId);
    if (userId == null) {
      amplitude.getInstance().regenerateDeviceId();
    }
  },
  /**
   * @param {Record<string, any>} properties
   */
  setUserProperties(properties) {
    if (!config.amplitudeEnabled) {
      return;
    }

    amplitude.getInstance().setUserProperties(properties);
  },
  /**
   * @param {string} customerId
   * @param {string} customerName
   */
  setUserCustomer(customerId, customerName) {
    if (!config.amplitudeEnabled) {
      return;
    }
    amplitude.getInstance().setUserProperties({
      customerId,
      customerName,
    });
  },
  /**
   * @param {string[]} locationIds
   * @param {string} locationNames
   */
  setUserLocations(locationNames) {
    if (!config.amplitudeEnabled) {
      return;
    }
    amplitude.getInstance().setUserProperties({
      locationNames,
    });
  },
  /**
   * @param {string} customerId
   * @param {string} customerName
   */
  setUserVendor(vendorId, vendorName) {
    if (!config.amplitudeEnabled) {
      return;
    }
    amplitude.getInstance().setUserProperties({
      vendorId,
      vendorName,
    });
  },
  /**
   * @param {string} eventName
   */
  logEvent(eventName, eventData) {
    if (!config.amplitudeEnabled || disable) {
      return;
    }
    amplitude.getInstance().logEvent(eventName, eventData);
  },
};
