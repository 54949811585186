import Vue from 'vue';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import businessDays from 'dayjs-business-days';
import calendar from 'dayjs/plugin/calendar';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(relativeTime);
dayjs.extend(businessDays);
dayjs.extend(calendar);
dayjs.extend(isBetween);

Vue.filter('YYYYMMDD', (value) => {
  if (!value) return '';
  return dayjs(value).format('YYYY-MM-DD');
});

Vue.filter('MMDDYYYY', (value) => {
  if (!value) return '';
  return dayjs(value).utc().format('MM/DD/YYYY');
});

Vue.filter('MMDDYYYYLocal', (value) => {
  if (!value) return '';
  return dayjs(value).format('MM/DD/YYYY');
});

Vue.filter('MMDDYY', (value) => {
  if (!value) return '';
  return dayjs(value).format('MM/DD/YY');
});

Vue.filter('MMMDDYYYY', (value) => {
  if (!value) return '';
  return dayjs(value).format('MMM DD, YYYY');
});

Vue.filter('MMDDYYYYHHMMA', (value) => {
  if (!value) return '';
  return dayjs(value).format('MM/DD/YYYY, hh:mm A');
});

Vue.filter('HHMM', (value) => {
  if (!value) return '';
  return dayjs(value).format('hh:mm');
});

Vue.filter('HHMMA', (value) => {
  if (!value) return '';
  return dayjs(value).format('hh:mm A');
});

Vue.filter('expirationDate', (value) => {
  const isValid = dayjs(value).isValid() && value !== undefined;
  return isValid ? dayjs(value).format('MM/DD/YYYY') : '(No Expiration Date)';
});

Vue.filter('ddddMMMMDDYYYY', (value) => {
  if (!value) return '';
  return dayjs(value).utc().format('dddd, MMMM DD, YYYY');
});

Vue.filter('dddMMMDDhhmmA', (value) => {
  if (!value) return '';
  return dayjs(value).format('ddd, MMM DD, hh:mmA');
});

Vue.filter('YYYYMMDDHHmmss', (value) => {
  if (!value) return '';
  return dayjs(value).format('YYYY-MM-DDTHH:mm:ssZ');
});

Vue.filter('baseDate', (value) => {
  if (!value) return '';
  return dayjs(value).format();
});

Vue.filter('calendarDate', (value) => {
  if (!value) return '';
  return dayjs(value).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'ddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] ddd',
  });
});
